<template>
  <div>
    <v-container fluid class="px-6 py-6">
       <div class="d-sm-flex justify-between">
        <v-btn
          v-if="permissions !='Freelancer'"
          @click="showNewOrderCard = true"
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-uppercase
            btn-default
            bg-gradient-primary
            py-2
            px-6
            me-2
            mb-sm-0 mb-2
          "
          color="#5e72e4"
          small
          >New Order</v-btn
        >
        
      </div>
      <v-row>
        <v-col cols="12">
            <v-row @keyup.enter="refreshOrders()">
                <v-col>
                  <v-text-field class="text-m text-typo ms-1" v-model="filter.orderId" label="Order ID" clearable  ></v-text-field>
                </v-col>
              <v-col>
                  <v-text-field class="text-m text-typo ms-1" v-model="filter.language" label="Language" clearable></v-text-field>
                </v-col>
              <v-col>
                  <v-select class="text-m text-typo ms-1" v-model="filter.status" :items="orderStatuses" item-text="status" item-value="status" label="Status" clearable></v-select>
                </v-col>
                  <v-col>
                  <v-select class="text-m text-typo ms-1" v-model="filter.order_type" :items="['Internal','3rd Party']" label="Order Type" clearable></v-select>
                </v-col>
                 <v-col v-if="permissions == 'Admin'">
                  <v-text-field class="text-m text-typo ms-1" v-model="filter.search_user" label="Search by user" clearable></v-text-field>
                </v-col>
                 <v-col v-if="permissions == 'Admin'">
                  <v-text-field class="text-m text-typo ms-1" v-model="filter.search_freelancer" label="Search by freelancer" clearable></v-text-field>
                </v-col>
             <v-col>
                  <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="filter.dateRange"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                          <template v-slot:activator="{ on, attrs }">
                        
                              <v-text-field
                                class="text-m text-typo ms-1"
                                v-model="dateRangeText"
                                label="Date range"
                                prepend-icon="fa-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                @click:clear="() => { filter.dateRange = []; refreshOrders()}"
                              ></v-text-field>
                          </template>
                            <v-date-picker
                              v-model="filter.dateRange"
                              range                              

                            >
                                <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="() => {$refs.menu.save(filter.dateRange); refreshOrders()}"
                            >
                              OK
                            </v-btn>
                            
                            </v-date-picker>
                        </v-menu>
             </v-col>
              
                 <hr class="vertical dark mb-4" />
            
            <div class="ps-6">
              <v-btn
                icon
                color="#fff"
                width="40px"
                height="40px"
                class="ms-auto btn-info bg-gradient-primary border-radius-md mt-4"
                @click="refreshOrders()"
              >
                <v-icon size="12">fa fa-redo</v-icon>
              </v-btn>
            </div>
            </v-row>
          </v-col>
        <v-col cols="12" style="overflow-x: scroll !important;">
             <v-progress-circular
                    class="w-75"
                    value="60"
                    height="3"
                    rounded
                    v-if="kanbanLoading"
                  ></v-progress-circular>
          <!-- <kanban-board :stages="orderStatuses" :blocks="orders">
            <div
              v-for="block in blocks"
              :slot="block.id"
              :key="block.id"
              class="pa-1"
            >
              <div v-if="block.image">
                <v-img :src="block.image" class="border-radius-sm mb-3"></v-img>
              </div>
              <div v-if="block.badge">
                <v-btn
                  elevation="0"
                  small
                  :ripple="false"
                  height="21"
                  class="
                    border-radius-sm
                    font-weight-bolder
                    px-2
                    py-2
                    badge-font-size
                    ms-auto
                    text-white text-xxs
                    mb-2
                  "
                  :class="`bg-gradient-` + block.badgeColor"
                  >{{ block.badge }}</v-btn
                >
              </div>
              <div v-if="block.title">
                <p class="text text-body mb-0">{{ block.title }}</p>
              </div>
              <div v-if="block.files">
                <div class="d-flex mt-3">
                  <div>
                    <i
                      class="fa fa-paperclip me-1 text-sm"
                      aria-hidden="true"
                    ></i>
                    <span class="text-sm">{{ block.files }}</span>
                  </div>
                  <span class="avatar-group ms-auto d-flex">
                    <v-tooltip
                      top
                      color="#212529"
                      v-for="avatar in block.avatars"
                      :key="avatar.name"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          v-bind="attrs"
                          v-on="on"
                          size="24"
                          class="border border-white"
                        >
                          <img :src="avatar.image" alt="Avatar" />
                        </v-avatar>
                      </template>
                      <span>{{ avatar.name }}</span>
                    </v-tooltip>
                  </span>
                </div>
              </div>
            </div>
          </kanban-board> -->

              <div v-if="noDataText == ''" class="min-h-screen d-flex overflow-x-scroll py-12">
                               <draggable class="d-flex">

                <div
                  v-for="(value, status) in orders"
                  :key="status"
                  class="blue-grey lighten-4 rounded-lg px-3 py-3 column-width rounded mr-4"
                  v-if="orders[status].length > 0"
                ><v-row class="mt-2 ml-1 mb-3 mr-2 d-flex">
                  <!-- <p :class="'font-semibold font-sans tracking-wide text-sm'">{{status}}</p> -->
                               <h5 class="text-h5 text-typo mb-0">
                                 {{ status }}
                              </h5>
                              <v-spacer></v-spacer>
                              {{ orders[status].length }} | {{ calculateTotalWords(status) }}
                        </v-row>      
                    <kanban-card
                      v-for="(order) in value"
                      :key="order.id"
                      :order="order"
                      class="mt-3"
                      @rightClick="duplicateOrder"
                      @assignedOrder='assignedOrder'
                      @deletedOrder='deletedOrder'
                      @acceptedOrder='acceptedOrder'
                      @rejectedOrder='rejectedOrder'
                      @freelancerOrderDecision='freelancerOrderDecision'
                      @unassignedOrder='unassignedOrder'
                      @uploadedFile='uploadedFile'
                    ></kanban-card>
                </div>
                                              </draggable>

              </div>

              <div v-else>
                    <v-alert :value="true" color="red lighten-2" icon="mdi-info">
                        {{ noDataText }}        
                     </v-alert>
              </div>


        </v-col>
      </v-row>

    </v-container>
     <new-order-card :dialog=showNewOrderCard v-if="showNewOrderCard"  @createdOrder="createdOrder"  @closeDialog="showNewOrderCard = false" :order=newOrder />
  </div>
</template>
<script>
import Vue from "vue";
import vueKanban from "vue-kanban";
import KanbanCard from "./Components/KanbanCard.vue"
import NewOrderCard from "./Components/NewOrderCard.vue"

import "vue-kanban/src/assets/kanban.scss";
const queryString = require('query-string');
import draggable from 'vuedraggable';

Vue.use(vueKanban);

export default {
  name: "Kanban",
  components: {
    draggable,
    KanbanCard,
    NewOrderCard
  },
  data: function () {
    return {
      showOrderDialog: false,
      kanbanLoading: true,
      menu: false,
      orderDialogData: {},
      orders: [],
      filter: {
        search_user: '',
        search_freelancer: '',
        status: '',
        language: '',
        orderId: '',
        month: '',
        delivery_date: '',
        dateRange: [new Date(new Date().getFullYear() , new Date().getMonth() - 2, 1).toLocaleDateString('fr-CA'),new Date(new Date().getFullYear() , new Date().getMonth()+1 , 0).toLocaleDateString('fr-CA')]
      },
      filterOut: {
        status: '',
      },
      orderStatuses: [],
      showNewOrderCard: false,
      noDataText: '',
      permissions: this.$store.state.permissions,
      duplicateOrderDetails: {time: "00:00"},
      newOrder : {time: "00:00"}
  
    };
  },
  computed: {
    dateRangeText () {
        var day = new Date(this.filter.dateRange[0]).getDate()
        var month = new Date(this.filter.dateRange[0]).getMonth()
        var year = new Date(this.filter.dateRange[0]).getFullYear()
        month = month+1

        var day1 = new Date(this.filter.dateRange[1]).getDate()
        var month1 = new Date(this.filter.dateRange[1]).getMonth()
        var year1 = new Date(this.filter.dateRange[1]).getFullYear()
        month1 = month1 +1

        return day + "-" + month + "-" + year + " - " + day1 + "-" + month1 + "-" + year1
        // return this.filter.dateRange.join(' - ')
      },
    totalWordCount () {
          let totalWords = 0 
          for(var orderStatus in this.orders){
            for(var order of this.orders[orderStatus]){
              totalWords += parseInt(order.word_count)
            }
          }
        
          return totalWords
      
     
    }
  },
   created: function (){
      this.$store.dispatch('getOrderStatuses')
       .then( (response) => {
             this.orderStatuses = response.data.data
       })
       .catch((e) => {
         this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })      
      })  

      if(this.permissions != 'Admin'){
        //this.filter.dateRange = [new Date(new Date().getFullYear() , new Date().getMonth() - 2, 1).toLocaleDateString('fr-CA'),new Date(new Date().getFullYear() , new Date().getMonth()+2 , 0).toLocaleDateString('fr-CA')]
          this.filter.dateRange = [new Date(new Date().getFullYear() , -1, 1).toLocaleDateString('fr-CA'),new Date(new Date().getFullYear() , new Date().getMonth()+2 , 0).toLocaleDateString('fr-CA')]
      }

      this.refreshOrders()
 
  },
  methods: {  
    refreshOrders(){
        this.kanbanLoading = true
         this.$store.dispatch('getOrdersByStatus', {query: queryString.stringify(this.filter)})
            .then( (response) => {
                  this.orders = response.data.data
                  if(Object.keys(this.orders).length <= 0){
                    if(this.permissions == 'Freelancer'){
                        this.noDataText = "Please fill in your payment and VAT information on the \"Settings\" page. If you are still seeing this message after filling in the information, then you don't yet have any orders assigned to you."
                    }else{
                      this.noDataText = "You don't have any orders right now."
                    }
                  }else{
                    this.noDataText = ""
                  }
                  this.kanbanLoading = false
            }  )
            .catch((e) => {
                this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })      
            })  

    },
    assignedOrder(order){
      var thiss = this
      this.orders[order.status].some(function(entry, i) {
        if (entry.id == order.id) {
            thiss.orders[order.status].splice(i, 1)

            entry.status = "Assigned"
            entry.freelancerId = order.freelancerId
              if(!("Assigned" in thiss.orders)){
              thiss.orders['Assigned'] = []
            }
            thiss.orders['Assigned'].unshift(entry)
            return true;
        }
       });
       if(this.orders[order.status].length <= 0){
         delete this.orders[order.status]
       }


    },
    // approveOrder(order){
    //   var thiss = this
    //   this.orders['Pending'].some(function(entry, i) {
    //     if (entry.id == order.id) {
    //         entry.status = "Approved"
    //          if(!("Approved" in thiss.orders)){
    //           thiss.orders['Approved'] = []
    //         }
    //         thiss.orders['Approved'].unshift(entry)
    //         thiss.orders['Pending'].splice(i, 1)
    //         return true;
    //     }
    //    });
    //     if(this.orders['Pending'].length <= 0){
    //      delete this.orders['Pending']
    //    }
    // },

    deletedOrder(order){
      var thiss = this
      this.orders[order.status].some(function(entry, i) {
        if (entry.id == order.id) {
            thiss.orders[order.status].splice(i, 1)
            return true;
        }
       });
       if(this.orders[order.status].length <= 0){
         delete this.orders[order.status]
       }
    },

    acceptedOrder(order){
      var thiss = this
      this.orders['Uploaded'].some(function(entry, i) {
        if (entry.id == order.id) {
            entry.status = "Done"
            if(!("Done" in thiss.orders)){
              thiss.orders['Done'] = []
            }
            thiss.orders['Done'].unshift(entry)
            thiss.orders['Uploaded'].splice(i, 1)
            return true;
        }
       });
       if(this.orders['Uploaded'].length <= 0){
         delete this.orders['Uploaded']
       }

    },

    rejectedOrder(order){
      var thiss = this
      this.orders['Uploaded'].some(function(entry, i) {

        if (entry.id == order.id) {
            entry.status = "Returned"
            if(!("Returned" in thiss.orders)){
              thiss.orders['Returned'] = []
            }
            thiss.orders['Returned'].unshift(entry)
            thiss.orders['Uploaded'].splice(i, 1)
            return true;
        }
       });

       if(this.orders['Uploaded'].length <= 0){
         delete this.orders['Uploaded']
       }

    },
    createdOrder(order){
      var thiss = this
      this.newOrder = {time: "00:00"}
      if(!("Pending" in thiss.orders)){ 
        thiss.orders['Pending'] = []
      }
      this.showNewOrderCard = false
      this.orders['Pending'].unshift(order)
      if(this.noDataText != ""){
        this.noDataText = ""
      }
    },
    duplicateOrder(order){
        this.$store.dispatch('getOrderWithId', {orderId: order.id})
            .then((response) => {
                this.showNewOrderCard = true;
                this.duplicateOrderDetails = response.data.data
                this.duplicateOrderDetails.rate_per_word = null
                this.duplicateOrderDetails.downloaded = null
                this.duplicateOrderDetails.rejection_note = null
                this.duplicateOrderDetails.similarity_score = null

                this.newOrder = this.duplicateOrderDetails
            })
            .catch((e) => {
                this.snackbarMsg = e.response.data.msg
                this.snackbar= true
            })   

    },
  
  freelancerOrderDecision(data){
    var order = data.order
    var decision = data.decision
    var thiss = this

    this.orders['Pending'].some(function(entry, i) {

        if (entry.id == order.id) {
          if(decision == 'reject'){
            //nothing happens on Freelancers side
              thiss.orders['Pending'].splice(i, 1)
              return true;
          }else if(decision == 'accept'){
              entry.status = "Accepted"
               if(!("Accepted" in thiss.orders)){
              thiss.orders['Accepted'] = []
            }
            thiss.orders['Accepted'].unshift(entry)
            thiss.orders['Pending'].splice(i, 1)
            return true;
          }
         
        }
       });

       if(this.orders['Assigned'].length <= 0){
         delete this.orders['Assigned']
       }
  },
  unassignedOrder(order){
      var thiss = this
      this.orders[order.status].some(function(entry, i) {

        if (entry.id == order.id) {

              thiss.orders[order.status].splice(i, 1)
              
              if(thiss.orders[order.status].length <= 0){
                  delete thiss.orders[order.status]
              }
              
              entry.status = "Pending"
              entry.freelancerId = null
              if(!("Pending" in thiss.orders)){
                thiss.orders['Pending'] = []
              }

              thiss.orders['Pending'].unshift(entry)

              return true;
          }
        });

       

    },
    calculateTotalWords(status){
      var totalWords = 0
      for(var order of this.orders[status]){
              totalWords += parseInt(order.word_count)
      }
      return this.number_format(totalWords,0,0,",")
    },
    uploadedFile(order){
      var thiss = this
      this.orders[order.status].some(function(entry, i) {

        if (entry.id == order.id) {

              thiss.orders[order.status].splice(i, 1)
              
              if(thiss.orders[order.status].length <= 0){
                  delete thiss.orders[order.status]
              }
              
              entry.status = "Uploaded"
              if(!("Uploaded" in thiss.orders)){
                thiss.orders['Uploaded'] = []
              }

              thiss.orders['Uploaded'].unshift(entry)

              return true;
          }
        });
    },
     number_format(number, decimals, dec_point, thousands_sep) {
      var n = !isFinite(+number) ? 0 : +number, 
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        toFixedFix = function (n, prec) {
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            var k = Math.pow(10, prec);
            return Math.round(n * k) / k;
        },
        s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
    }
  },

};
</script>
