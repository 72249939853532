<template>
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
     
      <v-card>
        <v-card-title class="text-h5">
          Delete Order
            <v-spacer>
                       </v-spacer>
                      <v-btn  class="mx-2"
                              fab
                              small
                              text
                              @click='$emit("closeDialog")'> <v-icon>
                      fas fa-times font-size-root
                    </v-icon> </v-btn>
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete this order?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="primary"
            text
            @click='$emit("closeDialog")'>
            No
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteOrder()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

  export default {
    name: 'DeleteOrderDialog',
    props: {
      orderId: {
          default: null,
          required: true
      },
      dialog: {
          type: Boolean,
          default: false
      },
     
    },
    data: function () {
    return {
    }
  },

  created: async function (){
    
    
  },

  methods: {
      deleteOrder () {
            this.$store.dispatch('deleteOrder', {orderId: this.orderId})
            .then( (response) => {
                this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
                this.$emit('deletedOrder') //and also change this to assigned status
            })
            .catch((e) => {
                this.$emit("closeDialog")           
                this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

            }) 
     },
    }
  }

</script>