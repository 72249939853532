<template>

    <v-dialog
          width="800px"
          v-model="dialog"
          persistent
          @click:outside="$emit('closeDialog')"

    > 
      <v-card
      class="mx-auto"
     >
  
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>


    <v-card-title> 
        
             <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                      Order Details
                    </h5>
           <v-spacer>
                       </v-spacer>
                      <v-btn  class="mx-2"
                              fab
                              small
                              text
                              @click='$emit("closeDialog")'> <v-icon>
                      fas fa-times font-size-root
                    </v-icon> </v-btn>
             </v-card-title>
<v-divider></v-divider>
<br>
    <v-card-text>
     
        <v-form
                  v-if="showOrderForm"
                  ref="orderForm"
                  id="orderDetailsForm"
                  lazy-validation>
                  <v-row>
                  <v-col>
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Order Title</label
                        >
                        <v-textarea
                           rows=1 
                        auto-grow
                        outlined
                        color="rgba(0,0,0,.6)"

                            class = "font-size-input
                          input-style
                          text-light-input 
                          mt-2"
                          

                        v-model="order.title"
                        readonly
                        ></v-textarea>    
                    </v-col>
                    <v-col v-if="permissions != 'Freelancer'"
                                ><label class="text-xs text-typo font-weight-bolder ms-1"
                          >Website</label
                        >
                        <v-text-field
                            v-model="order.website"
                             hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                                font-size-input
                                placeholder-lighter
                                border border-radius-md
                                mt-2
                            "
                            readonly
                        ></v-text-field>
                    </v-col>
                  </v-row>
                    <v-row class="mt-2">
                      <v-col sm="6" cols="12" class="mb-sm-0 mb-16" v-if="order.anchor != null">
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Anchor</label>
                        <v-textarea
                        rows=1 
                        auto-grow
                        outlined
                        v-model="order.anchor"
                        readonly
                        color="rgba(0,0,0,.6)"

                      class = "font-size-input
                          input-style
                          text-light-input 
                          mt-2"
                    ></v-textarea> 
                    </v-col>
                     
                     <v-col sm="6" cols="12">
                         <div v-if="permissions != 'Freelancer'">
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Order Type</label>
                        <v-select
                        :items="orderTypes"
                        v-model="order.orderTypeId"
                        item-text="type"
                        item-value="id"
                              color="rgba(0,0,0,.6)"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              border-radius-md
                              select-style
                              mt-2
                              mb-0
                            "
                            outlined
                            readonly
                        >

                    </v-select>
                         </div>
                         <div v-if='order.orderTypeId == 1'>
                         <label class="text-xs text-typo font-weight-bolder ms-1"
                          >3rd Party Url</label>
                    <v-text-field
                      outlined
                      v-model=order.external_url
                      readonly
                      hide-details
                      color="rgba(0,0,0,.6)"
                      light
                      class="
                          font-size-input
                          placeholder-lighter
                          border border-radius-md
                          mt-2"

                    ></v-text-field>  

                         </div>
                      </v-col>
                  
                  </v-row>
                    <v-row v-if="order.description != null">
                        <v-col>
                             <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Order Description</label>
                            <v-textarea
                                v-model="order.description"
                                 rows=1 
                                auto-grow
                                outlined
                                readonly
                                color="rgba(0,0,0,.6)"

                                class = "font-size-input
                                input-style
                                text-light-input 
                                mt-2"

                            ></v-textarea>
                        </v-col>
                    </v-row>       
                    <v-row>
                        <v-col v-if="order.focus_keyword != null">
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Focus Keyword</label>
                            <v-textarea
                                rows=1 
                                auto-grow
                                outlined
                                readonly
                                color="rgba(0,0,0,.6)"

                                   class = "font-size-input
                          input-style
                          text-light-input 
                          mt-2"
                                v-model="order.focus_keyword"
                            ></v-textarea>
                        </v-col>
                         <v-col v-if="order.sub_keyword != null">  
                             <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Sub Keyword</label>
                            <v-textarea
                                rows=1 
                                auto-grow
                                outlined
                                readonly
                                color="rgba(0,0,0,.6)"

                                    class = "font-size-input
                          input-style
                          text-light-input 
                          mt-2"
                                v-model="order.sub_keyword"

                            ></v-textarea>
                        </v-col>  
                    </v-row>
                    <v-row>
                        <v-col v-if="order.examples != null">
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Examples</label>
                            <v-textarea
                               rows=1 
                                auto-grow
                                outlined
                                readonly
                                color="rgba(0,0,0,.6)"

                                    class = "font-size-input
                          input-style
                          text-light-input 
                          mt-2"
                                v-model="order.examples"
                                ></v-textarea> 
                        </v-col>
                        
                        <v-col v-if="order.references != null">
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                          >References</label>
                                  <v-textarea
                                rows=1 
                                auto-grow
                                outlined
                                readonly
                                color="rgba(0,0,0,.6)"

                                    class = "font-size-input
                          input-style
                          text-light-input 
                          mt-2"
                                v-model="order.references"
                                ></v-textarea> 
                        </v-col>
                    </v-row>
                    <v-row  v-if="order.user_deadline != null && (permissions == 'Admin' || permissions == 'Countrymanager')">
                        <v-col>
                        <label class="text-xs text-typo font-weight-bolder ms-1">User Deadline</label>
                              <v-text-field
                                hide-details
                                outlined
                                color="rgba(0,0,0,.6)"
                                light
                                class="
                                    font-size-input
                                    placeholder-lighter
                                    border border-radius-md
                                    mt-2
                                "
                                readonly
                              :value='order.user_deadline.split("T")[0].split("-")[2] + "-" + order.user_deadline.split("T")[0].split("-")[1] + "-" + order.user_deadline.split("T")[0].split("-")[0]'
                            ></v-text-field>
                        </v-col>
                      </v-row>
                    <v-row v-if="order.deadline != null && (permissions == 'Admin' || permissions == 'Freelancer')">
                      <v-col>
                           <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Deadline</label>
                      
                          <v-text-field
                            readonly
                             hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                                font-size-input
                                placeholder-lighter
                                border border-radius-md
                                mt-2
                            "
                            :value='order.deadline.split("T")[0].split("-")[2] + "-" + order.deadline.split("T")[0].split("-")[1] + "-" + order.deadline.split("T")[0].split("-")[0]'
                          ></v-text-field>
                        </v-col>
                      <v-col>
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Time</label>
                            <v-text-field
                            readonly
                            v-model="order.time"
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                                font-size-input
                                placeholder-lighter
                                border border-radius-md
                                mt-2
                            "
                            >
                            </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                          <v-col v-if="order.orderStatus != null">
                               <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Order Status</label>
                                 <v-text-field
                                  hide-details
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      border border-radius-md
                                      mt-2
                                  "
                                 v-model="order.orderStatus.status"
                                 readonly
                                > </v-text-field>
                          </v-col>

                          <v-col v-if="order.focus_keyword_percentage != null">
                               <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Focus Keyword Percentage (%)</label>
                               <v-text-field
                                hide-details
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      border border-radius-md
                                      mt-2
                                  "
                                v-model="order.focus_keyword_percentage"
                                readonly
                                > </v-text-field>
                          </v-col>
                          <v-col v-if="order.sub_keyword_percentage != null">
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Sub Keyword Percentage (%)</label>
                                <v-text-field
                                  hide-details
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      border border-radius-md
                                      mt-2
                                  "
                                v-model="order.sub_keyword_percentage"
                                readonly
                                >  </v-text-field>
                          </v-col>

                      </v-row>
                    <v-row v-if="order.rejection_note != null">
                        <v-col>
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Rejection Note</label>
                            <v-textarea
                               rows=1 
                                auto-grow
                                outlined
                                readonly
                                color="rgba(0,0,0,.6)"
                                  class = "font-size-input
                          input-style
                          text-light-input 
                          mt-2"
                                v-model="order.rejection_note"
                            >  </v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                                <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Word Count</label>
                              <v-text-field
                                 hide-details
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      border border-radius-md
                                      mt-2
                                  "
                                v-model="order.word_count"
                                readonly
                            >
                            </v-text-field>
                        </v-col>    
                        <v-col v-if="(permissions=='Admin' || permissions =='Freelancer') && order.date_delivered != null">
                              <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Date Delivered</label>

                                <v-text-field
                                 hide-details
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      border border-radius-md
                                      mt-2
                                  "
                                :value="order.date_delivered.split('T')[0].split('-')[2] + '-' + order.date_delivered.split('T')[0].split('-')[1] + '-'+ order.date_delivered.split('T')[0].split('-')[0]"
                                readonly
                                >
                                </v-text-field>

                        </v-col>
                        <v-col v-if="order.words_delivered != null">
                              <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Words Delivered</label>
                             <v-text-field
                                  hide-details
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      border border-radius-md
                                      mt-2
                                  "
                                v-model="order.words_delivered"
                                readonly
                                >
                                </v-text-field>
                        </v-col>
                          <v-col v-if="permissions != 'Freelancer'">
                              <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Created by</label>
                             <v-text-field
                                  hide-details
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      border border-radius-md
                                      mt-2
                                  "
                                v-model="order.user.name"
                                readonly
                                >
                                </v-text-field>
                        </v-col>
                           <v-col v-if="(permissions == 'Admin') && order.freelancer">
                              <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Assigned to</label>
                             <v-text-field
                                  hide-details
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      border border-radius-md
                                      mt-2
                                  "
                                v-model="order.freelancer.name"
                                readonly
                                >
                                </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>

                                     <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Market</label>
                                   <v-img
                                    v-if="order.market != null"
                                    :src="require('@/assets/img/icons/flags/'+order.market.flag+'.svg')"
                                    max-width="35px"
                                    class="  font-size-input
                                      placeholder-lighter
                                      mt-2"
                                ></v-img>


                        </v-col>
                        <v-col v-if="permissions=='Admin' && order.total_cost != null">
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Total Cost (€)</label>
                                <v-text-field
                                  hide-details
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      border border-radius-md
                                      mt-2
                                  "
                                v-model="order.total_cost"
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                          
                    </v-row>
                    <v-row>
                    <v-col>
                         <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Downloaded</label>  
                                <v-switch
                                readonly
                                  class="switch"
                                  color="#3a416ff2"
                                  v-model="order.downloaded"
                                ></v-switch>
                    </v-col>
                    </v-row>
                  </v-form>

                  <v-form
                    v-if="showTemplateForm"
                    ref="templateForm"
                    id="templateDetailsForm"
                    lazy-validation>


                  <v-row v-if="permissions != 'Freelancer'">
                      <v-col>
                            <!-- <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Template</label> -->
                        <v-autocomplete
                            v-model="order.template"
                            :items="templates"
                           outlined
                            dense
                            readonly
                                color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      input-style 
                                      mt-2
                                  "
                            item-text="name"
                            item-value="id"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                      <v-list shaped>
                        <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                      Template
                    </h5>                    
                      <v-list-item-group
                        color="primary"
                      >
                    
                      <p color="black" v-if="order.orderTemplateDetails.length <= 0"> There is no template for this article. You may structure your text as you wish according to the order instructions.
                      </p>
                        <v-list-item
                          v-for="(item, i) in order.orderTemplateDetails"
                          :key="i"
                        >
      
                          <v-list-item-content>
                            <p :class="'ml-'+item.templateElement.spacing" :style="{'color': item.templateElement.color_code + '!important'}">{{ item.templateElement.element }}</p>
                          </v-list-item-content>
                          <v-row>
                            <v-col cols="4"><v-textarea label="Headline" auto-grow rows=1 readonly :value=item.headline> </v-textarea></v-col>
                           <v-col><v-textarea label="Instructions" auto-grow rows=1 readonly :value=item.instructions> </v-textarea></v-col>
                            </v-row>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    
                  </v-form>
    </v-card-text>

    <v-card-actions style="margin:auto;">
        <v-btn
        v-if="showTemplateForm == true && showOrderForm == false"
         :ripple="false"
                    :elevation="0"
                    class="
                      font-weight-bold
                      placeholder-lighter
                      text-xs text-dark
                      btn-light
                      bg-gradient-light
                      py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                    "
        @click=" () => { showTemplateForm = false; showOrderForm = true;}"
      >
        View Instructions
      </v-btn>    
      
          <v-spacer></v-spacer>
          <v-btn  
                    :elevation="0"
                    class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-default
                    py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                    "
                    color="primary"
                    v-if="showTemplateForm == true && showOrderForm == false && order.orderStatus.status == 'Pending' && permissions == 'Admin'"  @click="$emit('assignOrder')">Assign</v-btn>
      <v-btn
        v-if="showTemplateForm == false && showOrderForm == true"
        :elevation="0"
        class="
          font-weight-bold
          text-xs
          btn-default
          bg-gradient-default
          py-5
          px-6
          mt-6
          mb-2
          me-2
        "
        color="primary"
        @click=" () => { showTemplateForm = true; showOrderForm = false; }"
      >
        View Template
      </v-btn>
    </v-card-actions>
  
  </v-card>

 </v-dialog>
  
</template>
<style>
#orderDetailsForm input{
    color: black !important;
}
#orderDetailsForm textarea{
    color: black !important;
}
#templateDetailsForm textarea{
    color: black !important;

}
</style>
<script>

  export default {
    name: 'OrderCard',
    props: {
      order: {
          default: null,
          required: true
      },
      showCloseButton: {
        default: true
      },
      dialog: {
          type: Boolean,
          default: false
      }
    },
    data: function () {
    return {
      domains: [],
      showOrderForm: true,
      showTemplateForm: false,
      templates: [],
      templateElements: [],
      menu: false,
      permissions: this.$store.state.permissions,
      orderTypes: []
    }
  },
  watch:
  {
    order: function (){
       this.refreshOrderData()
       this.showOrderForm = true
       this.showTemplateForm = false
    }
  },
  created: async function (){
      this.$store
      .dispatch("getOrderTypes")
      .then(async (response) => {
          this.orderTypes = response.data.data

      })
      if(this.permissions == 'Countrymanager' && (this.order.orderStatus.status == 'Rejected' || this.order.orderStatus.status == 'Accepted' || this.order.orderStatus.status == 'Assigned') )
        this.order.orderStatus.status = 'Approved'
    
    this.refreshOrderData()
    console.log(this.order)
  },

  methods: {
      refreshOrderData(){
        this.$store
        .dispatch("getWebsites")
        .then(async (response) => {

            this.domains = response.data.data

        })
        if(this.permissions != 'Freelancer'){
              this.$store
              .dispatch("getTemplates")
              .then(async (response) => {
                  this.templates = response.data.data
              })    
        }
 
   
      }
    }
  }
</script>
