<template>
       <v-dialog
           v-model="dialog"
           persistent
           max-width="600px"
         >
                    <v-card
                      class="mx-auto"
                      max-width="600px"
                      outlined
                    >
                    <v-card-title> 
        
                       Upload File (Order: {{ order.id }})
                       <v-spacer>
                       </v-spacer>
                       <v-btn  class="mx-2"
                              fab
                              small
                              text
                              @click='$emit("closeDialog")'> <v-icon>
                      fas fa-times font-size-root
                    </v-icon> </v-btn>
                     </v-card-title>
                    <v-card-text>
                          <v-row> <v-col>Click the button below to upload your file.</v-col></v-row>
                        </v-card-text>
                  <v-divider></v-divider>
                  <br>
                      <v-card-text>
                         <v-file-input
                          :rules="[value => !value || value.size > 1 || 'Please upload the file in .docx format']"
                          required
                          v-model="file"
                          accept=".docx"
                          color="#F50F76"
                          label="Upload File"
                          placeholder="Upload File"
                          prepend-icon="mdi-paperclip"
                          outlined
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              color="#F50F76"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input> 
                  </v-card-text>

                      <v-card-actions>
                              <v-spacer></v-spacer>
                                <v-btn color=primary text @click="uploadPdfForOrder">Confirm File Upload</v-btn>

                      </v-card-actions>
                    </v-card>
                      <v-overlay       :z-index="1"
        :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

                  </v-dialog>   
                  
</template>

<script>

export default {
    name: 'uploadFileDialog',
    props: {
      order: {
          default: null,
          required: true
      },
      dialog: {
          type: Boolean,
          default: false
      },   
    },
    data: function () {
    return {
        file: {},
        overlay: false
    }
  },
  created: async function (){
      
  },
  methods: {  
    uploadPdfForOrder(){
        this.uploadPdfDialog = false
        //Show Loading spinner
        this.overlay = true
        if("size" in this.file){
             this.$store.dispatch('uploadFile', {orderId: this.order.id,file: this.file})
            .then( (response) => {
            
                 this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
                 this.$emit("uploadedFile")           

            })
            .catch((e) => {
                 this.$emit('closeDialog')
                 this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
            }) 
        }else{
            this.$store.commit('showMessage',{ content: "Kindly upload a valid file.", color: 'warning' })
        }  
      },  
    }
  }

</script>