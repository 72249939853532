<template>
  <div class="bg-white shadow rounded px-3 pt-3 pb-5 border border-white cursor" style= "width:14vw !important;" @contextmenu="handler($event)">
    <div class="flex justify-between">
        <v-row>
          <v-col cols="9">
    <p  @click="viewOrder()" class="text-gray-700 font-semibold font-sans tracking-wide text-sm">ID: {{ order.id }}<br>
	     Word Count: {{order.word_count}}<br>
     <template>Title: {{ order.title.split("–")[0] }} - {{ order.title.split("–")[1] }}<br></template> 

            	<template v-if="permissions != 'Freelancer'">Website: {{ order.website }}<br></template>

	
         	<template v-if="permissions == 'Admin'" >Created By: {{ order.userName }}<br></template> 
    	<template v-if="permissions == 'Admin'  && order.freelancerName" >Assigned to: {{ order.freelancerName }}<br></template> 
          	<template v-if="permissions == 'Countrymanager'  && order.freelancerId" >Assigned to: {{ order.freelancerId }}<br></template> 

          	<template v-if="(permissions == 'Admin' || permissions =='Countrymanager') && order.user_deadline" >Deadline user: {{ formatDate(order.user_deadline) }}<br></template> 
    	 <template v-if="(permissions == 'Freelancer' || permissions =='Admin' )&& order.deadline" >Deadline: {{ formatDate(order.deadline) }}<br></template>
      	 <template v-if="order.similarity_score != null" >Similarity Score: {{ order.similarity_score }}%<br></template>

     </p>
     </v-col>
    <v-col cols="3">
      <v-img
                                    v-if="order.marketFlag != null"
                                    :src="require('@/assets/img/icons/flags/'+order.marketFlag+'.svg')"
                                    max-width="35px"
                                    class="  font-size-input
                                      placeholder-lighter
                                      mt-2"
                                ></v-img>
    </v-col>
        </v-row>

            <v-row v-if="order.freelancerId == null && permissions == 'Admin'">            
            <!-- <v-btn  :elevation="0"
                    class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-default
                    py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                    "
                    color="primary"
                    v-if="order.status != 'Approved' && order.status != 'Rejected'"  @click="approveOrder()">Approve</v-btn> -->
            <v-btn   :elevation="0"
                    class="
                     font-weight-bold
                      placeholder-lighter
                      text-xs text-dark
                      btn-light
                      bg-gradient-light
                      py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                    "
                      v-if="order.status == 'Pending' || order.status == 'Rejected' || order.status == 'Approved'" text  @click="assignOrderDialog()">Assign</v-btn>

             
                </v-row>
                <v-row v-if="permissions == 'Admin' && order.status != 'Done' && order.freelancerId != null && order.html == null && order.filename == null">
                    <v-btn :elevation="0"
                    class="
                     font-weight-bold
                      placeholder-lighter
                      text-xs
                      bg-gradient-secondary
                      py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                    "
                     text @click="unassignOrder(order.id)">Unassign</v-btn>
                </v-row>
                <v-row>
                  <v-col v-if="permissions == 'Freelancer' && order.status == 'Pending'">
                    <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                              @click="orderDecision(order.id,'accept')"         
                              v-bind="attrs"
                              v-on="on"
                              color="green"
                            >
                              fa fa-check
                            </v-icon>
                    </template>
                    <span>Accept Order</span>
                  </v-tooltip>
                  </v-col>
                  
                  <v-col v-if="permissions == 'Freelancer' && order.status == 'Pending'">

                  <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                              @click="orderDecision(order.id,'reject')"         
                              v-bind="attrs"
                              v-on="on"
                              color="red"
                            >
                              fa fa-times
                            </v-icon>
                    </template>
                    <span>Reject Order</span>
                  </v-tooltip>

                  </v-col>

                  <v-col v-if="(  ( (order.downloaded == false && order.status == 'Uploaded') || order.status == 'Returned') || (order.status == 'Accepted' && order.filename == null ) ) && (permissions == 'Freelancer' || permissions == 'Admin' )">

                  <!-- <v-col v-if="(   (order.filename == null && order.status  != 'Pending' && order.status !='Uploaded' && order.status !='Assigned') && ( (order.status  == 'Returned') || (order.downloaded == false)) ) && permissions == 'Freelancer'"> -->
                  <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                              @click="showUploadDialog = true"         
                              v-bind="attrs"
                              v-on="on"
                            >
                              fa fa-file
                            </v-icon>
                    </template>
                    <span>Upload File</span>
                  </v-tooltip>
                  </v-col>

                    <v-col v-if='order.status == "Uploaded" && permissions !="Freelancer"'>
                    <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                              @click="acceptOrder()"
                              class="ml-2"
                              v-bind="attrs"
                              v-on="on"
                              color="green"
                            >
                              fa fa-check
                            </v-icon>
                    </template>
                    <span>Accept Order</span>
                  </v-tooltip>
                    </v-col>
                    <v-col v-if='order.status == "Uploaded" && permissions != "Freelancer"'>
                   <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                              @click="showRejectOrderDialog = true"
                              class="ml-2"
                              v-bind="attrs"
                              v-on="on"
                              color="red"
                            >
                              fa fa-times
                            </v-icon>
                    </template>
                    <span>Reject Order</span>
                  </v-tooltip>
                    </v-col>
                    <v-col v-if="order.filename != null">
            <v-tooltip
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                              <v-icon    v-bind="attrs"
                        v-on="on"
                        @click="downloadOrder"
                        >fa-paperclip</v-icon>
                    </template>
                    <span>Download File</span>
                  </v-tooltip>
                    </v-col>
                    <v-col v-if="order.filename != null">
                    <v-tooltip
                       bottom
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs"
                        v-on="on"
                        @click="$router.push({path: '/preview' , query: {orderId: order.id}})"
                        >fa-search</v-icon>
                    </template>
                    <span>Order Preview</span>
                  </v-tooltip>
                    </v-col>

                        <v-col >
          
                     <v-tooltip
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                              <v-icon    v-bind="attrs"
                        v-on="on"
                        @click="downloadOrderDetails()"
                        >fa-download</v-icon>
                    </template>
                    <span>Download order details</span>
                  </v-tooltip>
                    </v-col>
                    <v-col v-if='checkEditPermissions()'>
                <v-tooltip  bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                              @click="editOrder()"
                              v-bind="attrs"
                              v-on="on"
                            >
                              fa fa-edit
                            </v-icon>
                    </template>
                    <span>Edit Order</span>
                  </v-tooltip>
                    </v-col>
                    <v-col>   
                  <v-tooltip v-if="permissions == 'Admin' " bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2"
                              color="red"
                              @click="showDeleteOrderDialog = true"
                            >
                              fa fa-times
                            </v-icon>
                    </template>
                    <span>Delete Order</span>
                  </v-tooltip>
                  </v-col>
                 <v-col v-if="order.plagiarism_report != null">   
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                         <v-icon
                             v-bind="attrs"
                              v-on="on"
                            small
                            @click="downloadCopyleaksReport()"
                          >
                            fa fa-info
                          </v-icon>
                    </template>
                    <span>Plaigiarism Report</span>
                  </v-tooltip>
                  </v-col>
                  </v-row>
             
    </div>

    <order-card :dialog=showOrderDialog v-if="showOrderDialog" @assignOrder="assignOrderDialog" @closeDialog="showOrderDialog = false" :order=orderDialogData />
    <assign-order-card :dialog=showAssignOrderDialog v-if="showAssignOrderDialog" @assignedFreelancer="assignedFreelancer" @closeDialog="showAssignOrderDialog = false" :order=orderDialogData :freelancers=freelancers />
    <edit-order-card :dialog=showEditOrderDialog v-if="showEditOrderDialog" @closeDialog="showEditOrderDialog = false" :order=orderDialogData />
    <delete-order-dialog :dialog=showDeleteOrderDialog v-if="showDeleteOrderDialog" @deletedOrder="deletedOrder" @closeDialog="showDeleteOrderDialog = false" :orderId=order.id />
    <reject-order-dialog :dialog=showRejectOrderDialog v-if="showRejectOrderDialog" @rejectedOrder="rejectedOrder" @closeDialog="showRejectOrderDialog = false" :orderId=order.id />
    <upload-file-dialog :dialog=showUploadDialog v-if="showUploadDialog" @closeDialog="showUploadDialog = false" @uploadedFile="uploadedFile"  :order=order />

  </div>
  
</template>
<style>
.cursor{
    cursor: pointer;
}
</style>
<script>
import OrderCard from './OrderCard.vue'
import AssignOrderCard from "./AssignOrder.vue"
import EditOrderCard from "./EditOrderCard.vue"
import deleteOrderDialog from "./DeleteOrderDialog.vue"
import rejectOrderDialog from "./RejectOrderDialog.vue"
import uploadFileDialog from "./UploadFileDialog.vue"

export default {
 
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  components:{
      
          OrderCard,
          AssignOrderCard,
          EditOrderCard,
          deleteOrderDialog,
          rejectOrderDialog,
          uploadFileDialog

  },
  data: function () {
    return {
        showCard: false,
        showOrderDialog: false,
        orderDialogData: {},
        showAssignOrderDialog: false,
        showEditOrderDialog: false,
        showDeleteOrderDialog: false,
        showRejectOrderDialog: false,
        freelancers: [],
        permissions: this.$store.state.permissions,
        showUploadDialog: false

    }
  },
  computed: {

  },
  methods: {
    handler(e){
      this.$emit('rightClick',this.order)
      e.preventDefault();
    },
    downloadOrder (){
           var token = localStorage.getItem('token')
           var baseURL = process.env.VUE_APP_BASE_URL
           window.open(baseURL+"/ordersFile?id="+this.order.id+"&token="+token)  
    },
     downloadCopyleaksReport (){
           var token = localStorage.getItem('token')
           var baseURL = process.env.VUE_APP_BASE_URL
           window.open(baseURL+"/orders/copyleaks/pdf_report?id="+this.order.id+"&token="+token)  
    },
    assignOrderDialog () {
  
        this.$store.dispatch('getFreelancersByMarket', {marketId: this.order.marketId})
        .then( (response) => {
                this.freelancers = response.data.data
                this.orderDialogData = this.order
                this.showAssignOrderDialog = true
        }  )
        .catch((e) => {
            console.log(e)
        })  
    },    
    viewOrder () {
       
         this.$store.dispatch('getOrderWithId', {orderId: this.order.id})
            .then( (response) => {
                    this.orderDialogData = response.data.data
                    this.showOrderDialog = true
            }  )
            .catch((e) => {
            
              this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

            })   
    },
    editOrder () {
       
         this.$store.dispatch('getOrderWithId', {orderId: this.order.id})
            .then( (response) => {
                    this.orderDialogData = response.data.data
                    this.showEditOrderDialog = true
            }  )
            .catch((e) => {
              this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

            })   
    },
    assignedFreelancer(freelancerId){
        this.order.freelancerId = freelancerId
        this.showAssignOrderDialog = false
        this.$emit('assignedOrder',this.order)

    },
    deletedOrder(){

      this.showDeleteOrderDialog = false
      this.$emit('deletedOrder',this.order)

    },
    uploadedFile(){
       this.showUploadDialog = false
       this.$emit('uploadedFile',this.order)
    },
    // approveOrder(){
    //       this.$store.dispatch('approveOrder', {orderId: this.order.id})
    //         .then( (response) => {

    //           this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })

    //           //Emit event and move this order to orders['approved']
    //           this.$emit('approveOrder',this.order)
              
              
    //           }  )
    //         .catch((e) => {      
    //             this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
    //         })  
    // },
    acceptOrder(){
          this.$store.dispatch('acceptOrderByUser', {orderId: this.order.id})
            .then( (response) => {
                this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
                this.$emit('acceptedOrder',this.order)
            })
            .catch((e) => {
                this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
            }) 

    },
    rejectedOrder(){
        this.showRejectOrderDialog = false
        this.$emit('rejectedOrder',this.order)

    },
    downloadOrderDetails(){
        
         this.$store.dispatch('getOrderWithId', {orderId: this.order.id})
            .then( (response) => {

                    var order = response.data.data 

                    var orderTitleText = ""
                    var focusKeywordText = ""
                    var subKeywordText = ""
                    var anchorText = ""
                    var wordCountText = ""
                    var descriptionText = ""
                    var deadlineText = ""
                    var referencesText = ""
                    var externalUrlText = ""
                    var targetText = ""
                    var examplesText = ""

                    
                    if(order.title)
                       orderTitleText = "Title: ".bold() + order.title +"<br><br>"
                    if(order.focus_keyword)
                       focusKeywordText = "Focus Keyword: ".bold() + order.focus_keyword +"<br><br>"
                    if(order.sub_keyword)
                       subKeywordText = "Sub Keyword: ".bold() + order.sub_keyword +"<br><br>"
                    if(order.anchor)
                       anchorText = "Anchor: ".bold() + order.anchor +"<br><br>"
                    if(order.word_count)
                       wordCountText = "Word Count: ".bold() + order.word_count +"<br><br>"
                    if(order.description)
                       descriptionText = "<div style='max-width:800px;'>Description: ".bold() + order.description +"</div><br><br>"
                    if(order.deadline)
                       deadlineText = "Deadline: ".bold() + order.deadline + " " + order.time +"<br><br>"
                    if(order.references)
                       referencesText = "References: ".bold() + order.references +"<br><br>"
                    if(order.external_url)
                       externalUrlText = "3rd Party Url: ".bold() + order.external_url +"<br><br>"
                    if(order.target)
                       targetText = "Target: ".bold() + order.target +"<br><br>"
                    if(order.examples)
                       examplesText = "Examples: ".bold() + order.examples +"<br><br>"

                    var templateText = "Template:  ".bold() + "<br><br>"

                    console.log(order)
                    for(var templateElement of order.orderTemplateDetails){
                      templateText += templateElement.templateElement.element
                      if(templateElement.headline != null && templateElement.headline != ""){
                        templateText += " - " + templateElement.headline
                      }
                       if(templateElement.instructions != null && templateElement.instructions != ""){
                        templateText += " - " + templateElement.instructions
                      }
                        templateText +=  "<br><br>"
                    }

                    var text = orderTitleText+focusKeywordText+subKeywordText+referencesText+anchorText+externalUrlText+targetText+examplesText+wordCountText+descriptionText+deadlineText+templateText
                 
                    var a = document.body.appendChild(
                        document.createElement("a")
                    );
                    a.download = "ordersFile.html"; 
                    a.href = "data:text/plain;charset=utf-8," + text;
                    a.click();

            })
            .catch((e) => {
                 this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

            })   
      },
      orderDecision(id,decision){
           this.$store.dispatch('orderDecision', {orderId: id,decision: decision})
            .then( (response) => {

                    
                    this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
                     this.$emit('freelancerOrderDecision',{order: this.order, decision: decision})

            })
            .catch((e) => {
                  this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
            }) 
      },
      openplagiarismReport(url){
               window.open(url)  
      },
      unassignOrder(id)
      {
            this.$store.dispatch('unassignOrder', {orderId: id})
            .then( (response) => {
                this.$emit('unassignedOrder',this.order)

                this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })

              })
            .catch((e) => {
                this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
            })  
      },
      checkEditPermissions(){
        if(this.permissions == 'Freelancer'){
          return false
        }
        else if(this.permissions == 'Countrymanager' && this.order.status !='Rejected' && this.order.status !='In Progress'  && this.order.status !='Approved'  && this.order.freeelancerId == null){
          return true
        }else if(this.permissions == 'Admin' ){
          return true
        }

      },
      formatDate(date){
       return date.split('T')[0].split('-')[2] + '-' + date.split('T')[0].split('-')[1] + '-'+ date.split('T')[0].split('-')[0]
      }
   
  }
}

</script>
