<template>
    
             <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"
                  >
              
                    <v-card>
                      
                        <v-card-title> 
        
             <h5 class="font-weight-bolder text-h5 text-typo mb-0" style="white-space: nowrap !important;">
                    Assigning order with ID: {{ order.id }} for   <img
                                    v-if="order.marketFlag != null"
                                    :src="require('@/assets/img/icons/flags/'+order.marketFlag+'.svg')"
                                    style="display: inline !important;max-width: 35px !important;"
                                >
                    </h5>
           <v-spacer>
                       </v-spacer>
                      <v-btn  class="mx-2"
                              fab
                              small
                              text
                              @click='$emit("closeDialog")'> <v-icon>
                      fas fa-times font-size-root
                    </v-icon> </v-btn>
             </v-card-title>

                      <v-card-text>
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Freelancer</label>
                          <v-autocomplete
                          v-model="freelancer"
                          :items="freelancers"
                          outlined
                          dense
                          :item-text="getItemText"
                          item-value="id"
                                color="rgba(0,0,0,.6)"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              border-radius-md
                              select-style
                              mt-2
                              mb-0
                            "
                        ></v-autocomplete>
                        </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn     
                            color="#fff"
                            width="40px"
                            height="40px"
                            class="ms-auto btn-info bg-gradient-primary border-radius-md mt-4"
                            @click="assignOrder()"
                         >
                          Assign
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog> 

</template>

<script>

  export default {
    name: 'OrderCard',
    props: {
      order: {
          default: null,
          required: true
      },
      dialog: {
          type: Boolean,
          default: false
      },
      freelancers: {
          default: []
      }
    },
    data: function () {
    return {
      freelancer: {},
    }
  },
  watch:
  {
 
  },
  created: async function (){
    
    
  },

  methods: {
      assignOrder () {
          this.$store.dispatch('assignOrder', {orderId: this.order.id, freelancerId: this.freelancer})
            .then( (response) => {

                this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
                this.$emit('assignedFreelancer',this.freelancer) //and also change this to assigned status

            }  )
            .catch((e) => {
                 this.$store.commit('showMessage',{ content:  e.response.data.msg, color: 'warning' })
            })   
     },
     getItemText(item){
       var itemText = item.name
       if(item.comment){
         itemText += " " + item.comment
       }
       return itemText
     }
    }
  }

</script>
