<template>

    <v-dialog
          width="800px"
          v-model="dialog"
          persistent
    > 
      <v-card
      class="mx-auto"
     >
  
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>


    <v-card-title> 
        
             <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                      Order Details
                    </h5>
           <v-spacer>
                       </v-spacer>
                      <v-btn  class="mx-2"
                              fab
                              small
                              text
                              @click='$emit("closeDialog")'> <v-icon>
                      fas fa-times font-size-root
                    </v-icon> </v-btn>
             </v-card-title>
<v-divider></v-divider>
<br>
    <v-card-text>
     
        <v-form
                  v-show="showOrderForm"
                  ref="orderForm"
                  id="orderDetailsForm"
                  lazy-validation>
                  <v-row>
                  <v-col>
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Order Title</label
                        >
                        <v-textarea
                           rows=1 
                        auto-grow
                        outlined
                        color="rgba(0,0,0,.6)"

                        class = "font-size-input
                          input-style
                          text-light-input
                          
                          mt-2"
                          

                        v-model="order.title"
                        
                        ></v-textarea>    
                    </v-col>
                    <v-col v-if="permissions != 'Freelancer'"
                                ><label class="text-xs text-typo font-weight-bolder ms-1"
                          >Website</label
                        >
                        <v-text-field
                            v-model="order.website"
                             
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
                            "
                             :rules="[() => !!order.website || 'Website is required']"
                            required
                        ></v-text-field>
                    </v-col>
                  </v-row>
                    <v-row class="mt-2">
                   
                     
                     <v-col sm="6" cols="12">
                         <div v-if="permissions != 'Freelancer'">
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Order Type</label>
                        <v-select
                        :items="orderTypes"
                        v-model="order.orderTypeId"
                        item-text="type"
                        item-value="id"
                     
                            
                                outlined
                                      color="rgba(0,0,0,.6)"
                                      class="
                                        input-style
                                        font-size-input
                                        text-light-input
                                        placeholder-light
                                        border-radius-md
                                        select-style
                                        mt-2
                                        mb-0
                                      "
                                      single-line
                                      height="36"
                          >

                        </v-select>
                         </div>
                         <div v-if='order.orderTypeId == 1'>
                         <label class="text-xs text-typo font-weight-bolder ms-1"
                          >3rd Party Url</label>
                        <v-text-field
                          outlined
                          v-model=order.external_url
                          
                          
                          color="rgba(0,0,0,.6)"
                          light
                          class="
                              font-size-input
                              placeholder-lighter
                              input-style 
                              mt-2"

                        ></v-text-field>  

                         </div>
                      </v-col>
                     <v-col sm="6" cols="12" class="mb-sm-0 mb-16">
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Anchor</label>
                        <v-textarea
                        rows=1 
                        auto-grow
                        outlined
                        v-model="order.anchor"
                        color="rgba(0,0,0,.6)"

                        class = "font-size-input
                          input-style
                          text-light-input
                          
                          mt-2"
                    ></v-textarea> 
                    </v-col>
                  </v-row>
                    <v-row >
                        <v-col>
                             <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Order Description</label>
                            <v-textarea
                                v-model="order.description"
                                 rows=1 
                                auto-grow
                                outlined
                                color="rgba(0,0,0,.6)"

                                class = "font-size-input
                                input-style
                                text-light-input
                                
                                mt-2"

                            ></v-textarea>
                        </v-col>
                    </v-row>       
                    <v-row>
                        <v-col>
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Focus Keyword</label>
                            <v-textarea
                                rows=1 
                                auto-grow
                                outlined
                                color="rgba(0,0,0,.6)"

                                class = "font-size-input
                                input-style
                                text-light-input
                                
                                mt-2"
                                v-model="order.focus_keyword"
                            ></v-textarea>
                        </v-col>
                         <v-col>  
                             <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Sub Keyword</label>
                            <v-textarea
                                rows=1 
                                auto-grow
                                outlined
                                color="rgba(0,0,0,.6)"

                                class = "font-size-input
                                input-style
                                text-light-input
                                
                                mt-2"
                                v-model="order.sub_keyword"

                            ></v-textarea>
                        </v-col>  
                    </v-row>
                    <v-row>
                        <v-col>
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Examples</label>
                            <v-textarea
                               rows=1 
                                auto-grow
                                outlined
                                color="rgba(0,0,0,.6)"

                                class = "font-size-input
                                          input-style

                                text-light-input
                                mt-2"
                                v-model="order.examples"
                                ></v-textarea> 
                        </v-col>
                        
                        <v-col>
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                          >References</label>
                                  <v-textarea
                                rows=1 
                                auto-grow
                                outlined
                                color="rgba(0,0,0,.6)"

                                class = "font-size-input
                                input-style
                                text-light-input
                                
                                mt-2"
                                v-model="order.references"
                                ></v-textarea> 
                        </v-col>
                    </v-row>
                    <v-row v-if="checkOrderDeadline()">
                        <v-col>
                                <v-alert 
                                color="red"
                                dark
                                class="text-center"
                                >
                                    Order deadline is less than 48 hours!
                                </v-alert>
                        </v-col>
                    </v-row>
                    <v-row  v-if="permissions == 'Admin' || permissions == 'Countrymanager'">
                        <v-col>
                        <label class="text-xs text-typo font-weight-bolder ms-1">User Deadline</label>
                        
                          <v-menu
                          v-if="permissions == 'Admin' || permissions == 'Countrymanager'"
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          :return-value.sync="order.user_deadline"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="order.user_deadline"
                              prepend-icon="fa fa-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              
                              outlined
                              color="rgba(0,0,0,.6)"
                              light
                              clearable
                              class="
                                  font-size-input
                                  placeholder-lighter
                                  input-style 
                                  mt-2
                              "
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="order.user_deadline"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="menu2 = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu2.save(order.user_deadline)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>


                        </v-col>
                      </v-row>
                    <v-row v-if="permissions == 'Admin'">
                     
                      <v-col>
                           <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Deadline</label>
                      
                       

                                   <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="order.deadline"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          v-if="permissions == 'Admin'"

                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="order.deadline"
                              prepend-icon="fa fa-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on" 
                              clearable
                              
                              outlined
                              color="rgba(0,0,0,.6)"
                              light
                              class="
                                  font-size-input
                                  placeholder-lighter
                                  input-style 
                                  mt-2
                              "

                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="order.deadline"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(order.deadline)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>

                        </v-col>
                      <v-col>
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Time</label>
                          
                            <v-select
                            :items="['12:00','23:59']"
                            v-model="order.time"
                            v-if="permissions == 'Admin'"
                             class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              select-style
                              mt-2
                              mb-0
                            "
                            single-line
                            height="36"
                            outlined
                            >
                            </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
      

                          <v-col>
                               <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Focus Keyword Percentage (%)</label>
                               <v-text-field
                                
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      input-style 
                                      mt-2
                                  "
                                v-model="order.focus_keyword_percentage"
                                
                                > </v-text-field>
                          </v-col>
                          <v-col>
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Sub Keyword Percentage (%)</label>
                                <v-text-field
                                  
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      input-style 
                                      mt-2
                                  "
                                v-model="order.sub_keyword_percentage"
                                
                                >  </v-text-field>
                          </v-col>

                      </v-row>
                    <v-row v-if="permissions != 'Freelancer' && order.rejection_note">
                        <v-col>
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Rejection Note</label>
                            <v-textarea
                               rows=1 
                                auto-grow
                                outlined
                                
                                color="rgba(0,0,0,.6)"
                                class = "font-size-input
                                input-style
                                text-light-input
                                
                                mt-2"
                                v-model="order.rejection_note"
                            >  </v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                                <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Word Count</label>
                              <v-text-field
                                 
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      input-style 
                                      mt-2
                                  "
                                v-model="order.word_count"
                                
                            >
                            </v-text-field>
                        </v-col>    
                  
                        <v-col>
                              <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Words Delivered</label>
                             <v-text-field
                                  
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      input-style 
                                      mt-2
                                  "
                                v-model="order.words_delivered"
                                
                                >
                                </v-text-field>
                        </v-col>
                    
                    </v-row>

                    <v-row>
                        <v-col>


                                     <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Market</label>

                                    <v-select
                                      :items="markets"
                                      v-model="order.marketId"
                                      item-text="code"
                                      item-value="id"
                                      outlined
                                      color="rgba(0,0,0,.6)"
                                      class="
                                        input-style
                                        font-size-input
                                        text-light-input
                                        placeholder-light
                                        border-radius-md
                                        select-style
                                        mt-2
                                        mb-0
                                      "
                                      single-line
                                      height="36"
                            
                                      :rules="[() => !!order.marketId || 'Market is required']"
                                      required
                                    >
                                      <template slot="selection" slot-scope="data">
                                                <div class="pr-2"> {{ data.item.market_name }}</div>
                                                 <v-img
                                                v-if="order.market != null"
                                                :src="require('@/assets/img/icons/flags/'+data.item.flag+'.svg')"
                                                max-width="25px"
                                                class="font-size-input
                                                  placeholder-lighter
                                                  "
                                            ></v-img>                            
                                          </template>
                                      <template slot="item" slot-scope="data" >
                                                <div class="pr-2"> {{ data.item.market_name }} </div>
                                                    <v-img
                                                        v-if="order.market != null"
                                                        :src="require('@/assets/img/icons/flags/'+data.item.flag+'.svg')"
                                                        max-width="25px"
                                                        class="  font-size-input
                                                          placeholder-lighter
                                                          mt-2"
                                                    ></v-img>                                
                                              </template>
                                    </v-select>

                                 


                        </v-col>
                           <v-col v-if="permissions=='Admin'">
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Rate Per Word (€)</label>
                                <v-text-field
                                  
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      input-style 
                                      mt-2
                                  "
                                v-model="order.rate_per_word"
                                
                                >
                            </v-text-field>
                        </v-col>
                        <v-col v-if="permissions=='Admin'">
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Total Cost (€)</label>
                                <v-text-field
                                  
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      input-style 
                                      mt-2
                                  "
                                v-model="order.total_cost"
                                
                                >
                            </v-text-field>
                        </v-col>
                          
                    
                    </v-row>
                         <v-row v-if="permissions == 'Admin'">
                    <v-col>
                         <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Downloaded</label>  
                                <v-switch
                                
                                  class="switch"
                                  color="#3a416ff2"
                                  v-model="order.downloaded"
                                ></v-switch>
                    </v-col>
                    </v-row>
                  </v-form>

                  <v-form
                    v-show="showTemplateForm"
                    ref="templateForm"
                    id="templateDetailsForm"
                    lazy-validation>


                  <v-row v-if="permissions != 'Freelancer'">
                      <v-col cols="10">
                            <!-- <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Template</label> -->
                        <v-autocomplete
                            v-model="order.template"
                            :items="templates"
                            outlined
                            dense
                            
                                color="rgba(0,0,0,.6)"
                                  lights
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      input-style 
                                      mt-2
                                  "
                            item-text="name"
                            item-value="id"
                            @change="getTemplateDetails(order.templateId)"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                            <v-btn
                              v-if="useOriginalTemplate == false"
                              :elevation="0"
                              class="
                                font-weight-bold
                                text-xs
                                btn-default
                                bg-gradient-default
                                mt-2
                              "
                              color="primary"
                              @click=" () => { useOriginalTemplate = true; order.template = originalTemplateId }"
                            >
                              Revert
                            </v-btn>
                      </v-col>
                    </v-row>
                      <v-list shaped>
                        <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                      Template
                    </h5>    
                      <v-list-item-group
                        color="primary"
                        v-if="useOriginalTemplate && order.orderTemplateDetails"
                      >

                                 <v-row>
                           <v-col cols="5">
                          <v-autocomplete
                          v-model="chosenElement"
                          :items="elements"
                          outlined
                          dense
                          item-value="element"
                          item-text="element"
                          placeholder="Template Element"
                          color="rgba(0,0,0,.6)"
                          lights
                          class="
                              font-size-input
                              placeholder-lighter
                              input-style 
                              mt-2
                          "
                          return-object
                        ></v-autocomplete>
                          </v-col>
                          <v-col cols="1">
                          <v-icon class="mt-4" @click="addChosenElement(true)">fa fa-plus</v-icon>
                        </v-col>
                      
                        </v-row>
                       <draggable
                          v-model="order.orderTemplateDetails"
                        >
                        <v-list-item
                          v-for="(item, i) in order.orderTemplateDetails"
                          :key="i"
                        >
                          <v-list-item-content v-if='item.templateElement'>
                            <p :class="'ml-'+item.templateElement.spacing" :style="{'color': item.templateElement.color_code + '!important'}">{{ item.templateElement.element }}</p>
                          </v-list-item-content>
                          <v-row>
                            <v-col cols="4"><v-textarea label="Headline"   
                        color="rgba(0,0,0,.6)"

                        class = "font-size-input
                          input-style
                          text-light-input
                          
                          mt-2"
                           auto-grow rows=1  v-model=item.headline> </v-textarea></v-col>
                           <v-col><v-textarea label="Instructions"  
                        color="rgba(0,0,0,.6)"

                        class = "font-size-input
                          input-style
                          text-light-input
                          
                          mt-2"
                           auto-grow rows=1  v-model=item.instructions> </v-textarea></v-col>
                            <v-col cols="1"> <v-icon color="red" @click="deleteElement(i,true)">fa fa-minus</v-icon> </v-col>

                            </v-row>
                        </v-list-item>
                       </draggable>
                      </v-list-item-group>
                        <v-list-item-group
                        color="primary"
                        v-else
                      >
                        <v-row>
                           <v-col cols="5">
                          <v-autocomplete
                          v-model="chosenElement"
                          :items="elements"
                          outlined
                          dense
                          item-value="element"
                          item-text="element"
                          placeholder="Template Element"
                            
                                color="rgba(0,0,0,.6)"
                                  lights
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      input-style 
                                      mt-2
                                  "
                          return-object
                        ></v-autocomplete>
                          </v-col>
                          <v-col cols="1">
                          <v-icon class="mt-4" @click="addChosenElement()">fa fa-plus</v-icon>
                        </v-col>
                      
                        </v-row>
                       <draggable
                          v-model="templateElements"

                        >

                          <v-list-item
                          v-for="(item, i) in templateElements"
                          :key="i"
                        >
                          <v-list-item-content>
                            <p :class="'ml-'+item.templateElement.spacing" :style="{'color': item.templateElement.color_code + '!important'}">{{ item.templateElement.element }}</p>
                          </v-list-item-content>
                          <v-row>
                            <v-col cols="4"><v-textarea  
                        color="rgba(0,0,0,.6)"

                        class = "font-size-input
                          input-style
                          text-light-input
                          
                          mt-2"
                           label="Headline" auto-grow rows=1  v-model=item.default_headline> </v-textarea></v-col>
                           <v-col><v-textarea label="Instructions"  
                        color="rgba(0,0,0,.6)"

                        class = "font-size-input
                          input-style
                          text-light-input
                          
                          mt-2"
                           auto-grow rows=1  v-model=item.default_instructions> </v-textarea></v-col>
                            </v-row>
                              <v-col cols="1"><v-icon color="red" @click="deleteElement(i)">fa fa-minus</v-icon></v-col>

                        </v-list-item>
                       </draggable>
                      </v-list-item-group>
                    </v-list>
                    
                  </v-form>
    </v-card-text>

    <v-card-actions style="margin:auto;">

    

        <v-btn
        v-if="showTemplateForm == true && showOrderForm == false"
         :ripple="false"
                    :elevation="0"
                    class="
                      font-weight-bold
                      placeholder-lighter
                      text-xs text-dark
                      btn-light
                      bg-gradient-light
                      py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                    "
        @click=" () => { showTemplateForm = false; showOrderForm = true;}"
      >
        View Instructions
      </v-btn>  
          <v-spacer   v-if="showTemplateForm == true && showOrderForm == false"></v-spacer>
       <v-btn
                color="#fff"
                class="ms-auto btn-info bg-gradient-primary border-radius-md mt-4
                      placeholder-lighter
                      text-xs
                      py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                "
                :disabled="disableFinishButton"

                @click="editOrder()"
              >
                <v-icon size="12" class="pr-4">fa fa-check</v-icon>
                <template v-if="showTemplateForm == true && showOrderForm == false">Finish</template>
                <template v-else>Save</template>
              </v-btn>
                
          <v-spacer   v-if="showTemplateForm == false && showOrderForm == true"></v-spacer>
      <v-btn
        v-if="showTemplateForm == false && showOrderForm == true"
        :elevation="0"
        class="
          font-weight-bold
          text-xs
          btn-default
          bg-gradient-default
          py-5
          px-6
          mt-6
          mb-2
          me-2
        "
        color="primary"
        @click=" () => { showTemplateForm = true; showOrderForm = false; }"
      >
        View Template
      </v-btn>
    </v-card-actions>
  
  </v-card>

     

 </v-dialog>
  
</template>
<style>
#orderDetailsForm input{
    color: black !important;
}
#orderDetailsForm textarea{
    color: black !important;
}
#templateDetailsForm textarea{
    color: black !important;

}
</style>
<script>
import draggable from 'vuedraggable';
var momentBusinessDays = require("moment-business-days")

  export default {
    name: 'OrderCard',
    components: {
            draggable
    },
    props: {
      order: {
          default: null,
          required: true
      },
      showCloseButton: {
        default: true
      },
      dialog: {
          type: Boolean,
          default: false
      }
    },
    data: function () {
    return {
      chosenElement: {},
      markets: [],
      domains: [],
      showOrderForm: true,
      showTemplateForm: false,
      templates: [],
      templateElements: [],
      menu: false,
      permissions: this.$store.state.permissions,
      orderTypes: [],
      menu2: false,
      menu: false,
      disableFinishButton: false,
      useOriginalTemplate: true,
      originalTemplateId: 0,
      elements: [],
    }
  },
  watch:
  {
    order: function (){
       this.refreshOrderData()
       this.showOrderForm = true
       this.showTemplateForm = false
    },
     
     'order.marketId'(newVal){
        //to work with changes in prop
        this.updateTemplates(newVal)
    }
  },


  created: async function (){


      this.$store.dispatch('getTemplateElements')
      .then( async (response) => {
        this.elements = response.data.data
      })
      .catch(() => {
        this.$store.commit('showMessage',{ content: "Error while getting template elements", color: 'warning' })
      })
      this.$store
      .dispatch("getOrderTypes")
      .then(async (response) => {
          this.orderTypes = response.data.data

      })
      if(this.permissions == 'Countrymanager' && (this.order.orderStatus.status == 'Rejected' || this.order.orderStatus.status == 'Accepted' || this.order.orderStatus.status == 'Assigned') )
        this.order.orderStatus.status = 'Approved'

  

    this.refreshOrderData()
    this.originalTemplateId = this.order.templateId
  },

  methods: {
      checkOrderDeadline(){
          if(new Date (this.order.user_deadline) < momentBusinessDays(new Date()).businessAdd(2)._d)
            return true
          else
            return false  
        
      },
      refreshOrderData(){
        console.log(this.order)
        
        this.$store
        .dispatch("getWebsites")
        .then(async (response) => {

            this.domains = response.data.data

        })
          this.$store
          .dispatch("getTemplates")
          .then(async (response) => {
              this.templates = response.data.data
          })
    
      this.$store.dispatch('getMarkets')
        .then( async (response) => {

              this.markets = response
        }  )
        .catch(() => {
     
          this.$store.commit('showMessage',{ content: "Error while loading markets data...", color: 'warning' })

        })    
     
    
      },

      addChosenElement(originalOrder = false){
      if("element" in this.chosenElement){
             if(originalOrder){
              this.order.orderTemplateDetails.push({templateElement: {id: this.chosenElement.id ,spacing:this.chosenElement.spacing, element: this.chosenElement.element, color_code: this.chosenElement.color_code}, instructions: "", headline: ""})
        }else{
            this.templateElements.push({templateElement: {id: this.chosenElement.id ,spacing:this.chosenElement.spacing, element: this.chosenElement.element, color_code: this.chosenElement.color_code}, instructions: "", headline: ""})
        }
        this.chosenElement = {}
        }else{
           this.$store.commit('showMessage',{ content: "Please select a template element first!", color: 'warning' })
        }
     
      },
      deleteElement(index,originalOrder = false){
        if(originalOrder){
               this.order.orderTemplateDetails.splice(index,1)
        }else{
                  this.templateElements.splice(index,1)
        }
      },
      editOrder(){
          
          if(this.$refs.templateForm.validate() && this.$refs.orderForm.validate()){

                if(this.useOriginalTemplate)
                  this.templateElements = []
                
                 this.$store.dispatch('editOrder',{order: this.order, templateElements: this.templateElements})
                 .then( async (response) => {
                         this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
                         this.$emit('closeDialog')

                 }  )
                 .catch((err) => {
                     this.$store.commit('showMessage',{ content: err.response.data.msg, color: 'warning' })

                 })    

           }else{                 
                this.$store.commit('showMessage',{ content:  "Please fill in the missing fields.", color: 'success' })
           }
     

           
      },
      getTemplateDetails(templateId){
        this.$store.dispatch('getTemplateDetails', {templateId: templateId})
        .then( async (response) => {
              this.templateElements = response.data.data
              this.useOriginalTemplate = false
              
        }  )
        .catch(() => {
      
          this.$store.commit('showMessage',{ content: "Error while loading template elements...", color: 'warning' })

        })    

      },
      updateTemplates(marketId){
          this.$store
          .dispatch("getTemplatesByMarket",{marketId: marketId})
          .then(async (response) => {
              this.templates = response.data.data
          })
       
      },
    }
  }
</script>
