<template>
      <v-dialog
             v-model="dialog"
             max-width="500px"
             persistent
     >

    <v-card
      class="mx-auto"
      outlined
    >
    <v-card-title class="text-h5">
         Reject Order ID: {{ orderId }}
           <v-spacer>
                       </v-spacer>
                      <v-btn  class="mx-2"
                              fab
                              small
                              text
                              @click='$emit("closeDialog")'> <v-icon>
                      fas fa-times font-size-root
                    </v-icon> </v-btn>
    </v-card-title>
  <v-divider></v-divider>
  <br>
      <v-card-text>
            <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Order Feedback</label
                        >
         <v-textarea
         
         v-model="orderRejectionNote"
          rows=1 
                        auto-grow
                        outlined
                        color="rgba(0,0,0,.6)"

                        class = "font-size-input
                          input-style
                          text-light-input      
                          mt-2"
         >
       

         </v-textarea> 
  </v-card-text>
      <v-card-actions>
              <v-spacer></v-spacer>
                   <v-btn     
                            color="#fff"
                            width="40px"
                            height="40px"
                            class="ms-auto btn-info bg-gradient-primary border-radius-md mt-4"
                            @click="rejectOrder()"
                         >
                          Reject
                        </v-btn>
      </v-card-actions>
    </v-card>

    </v-dialog>   
</template>

<script>

  export default {
    name: 'rejectOrderDialog',
    props: {
      orderId: {
          default: null,
          required: true
      },
      dialog: {
          type: Boolean,
          default: false
      },
     
    },
    data: function () {
    return {
        orderRejectionNote: ""
    }
  },

  created: async function (){
    
    
  },

  methods: {
      rejectOrder () {
        if(this.orderRejectionNote != ""){
            this.$store.dispatch('rejectOrderByUser', {orderId: this.orderId,rejection_note: this.orderRejectionNote})
            .then( (response) => {

                    this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
                    this.$emit("rejectedOrder")           

            })
            .catch((e) => {
                
                this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
                this.$emit("closeDialog")           

            })   
        }else{
            this.$store.commit('showMessage',{ content: "Please fill in the order feedback.", color: 'warning' })
        }
        
     },
    }
  }

</script>